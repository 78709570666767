import $ from 'jquery';

jQuery(document).ready(function(){
	jQuery('#nav-icon1').click(function(){
        jQuery(this).toggleClass('open');
        jQuery('body').toggleClass('open-menu');
        jQuery('.header-menu').toggleClass('header-menu-open');
	});
});

// Header - Add Sticky Header Function.
jQuery(document).on('scroll', function() {
    if (jQuery(document).scrollTop() > 154) {
        jQuery('.site-header').addClass('sticky-header');
    } else {
        jQuery('.site-header').removeClass('sticky-header');
    }
});

// Scroll to Section - Add Scroll to Section Function.
jQuery(".scroll-to-section a[href^='#']").click(function(e) {
    e.preventDefault();
    var position = jQuery(jQuery(this).attr("href")).offset().top;
    jQuery("body, html").animate({
        scrollTop: position
    } /* speed */ );
});

var swiper = new Swiper(".heroHeaderSwiper", {
    spaceBetween: 0,
    effect: "fade",
    loop: true,
    speed: 2000,
    autoplay: {
        delay: 6000,
        disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
});